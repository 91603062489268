import '../stylesheets/login.scss';
require("@rails/actiontext");
require("@rails/activestorage").start();

import "channels"

import jQuery from "jquery";
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;
require('bootstrap/dist/js/bootstrap');
require('popper.js/dist/popper.min');

import Turbolinks from 'turbolinks'
import "controllers"

Turbolinks.start();


